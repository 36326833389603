import React, { useState, useEffect } from 'react'
import { session } from '../../utils/AuthSession'
const PremiumBroadcast = () => {
    const [authSession, setAuthSession] = useState(session())

    return (
        <>
            {authSession ? (
                <div className='text-white bg-black h-64 rounded-xl p-5 mt-3 sm:hidden'>
                    {/* <div>
                        Point Anda : 7732947
                    </div>

                    <div className='w-full'>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Isi pesan</span>
                            </label>
                            <input type="text" placeholder="Type here" className="input input-bordered w-full" />
                            <button className='btn btn-active mt-1 bg-blue-950 border-none'>Kirim pesan</button>
                        </div>
                    </div> */}
                </div>
            ) : ('')}
        </>




    )
}

export default PremiumBroadcast