import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import axios from 'axios'

function Blog() {
    const { slug } = useParams()
    const [blog,setBlog] = useState()
    const [other,setOther] = useState([])

    useEffect(()=>{
        init()
        postIP()
    },[])

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method : API.IP.type,
            access: 'no auth',
            reqBody: {
                page: `/blog/${slug}`,
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    const init = async () => {
        const params = {
            url : API.BLOG.url,
            access : 'no auth',
            method: 'get',
            reqBody: {
                slug: slug
            }
        }

        const response = await Api(params)
        if(response.status) {
            setBlog(response.data)
            setOther(response.other.data)
        }
    }

    return (
        <div>
            <div className='bg-white text-black m-5 p-5 rounded-xl md:ml-36 md:mr-36 xl:ml-30 xl:mr-30'>
                
                <div className='w-full'>
                    <img src={blog?.image} className="w-full rounded-xl" alt="" />
                </div>
                <div className='pt-5 pb-5 text-2xl font-bold'>{blog?.title}</div>
                <div>
                    <div dangerouslySetInnerHTML={{__html: blog?.full_content}} className='font-semibold sm:mb-5 xl:pl-0 md:pl-0'/>
                </div>
            </div>
            
            
        </div>
    )
}

export default Blog