
import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"
import moment from "moment"
import Banner from "../../assets/banner-mic-chart.png"
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'


const Chart = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        Init();
        postIP();
    }, [])

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method : API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/chart',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    const Init = async () => {
        const params = {
            method : 'get',
            url: API.MICCHART.url,
            access: 'no auth',
            regBody: {}
        }

        const response = await Api(params)
        if(response.status){
            setData(response.data)
        }
        
    }

    return (
        <div className='flex flex-col justify-center items-center p-10 text-white'>
            <div className='text-2xl font-bold pb-10'>MIC Chart Minggu ini</div>
            <div className='flex flex-col gap-5 md:flex-row '>
                <div className='w-100 md:w-1/4'><img src={data?.image} alt="" /></div>
                <div className='w-100 md:w-2/4 text-white'>
                    <div dangerouslySetInnerHTML={{__html: data?.title}} className='text-white xl:text-2xl font-semibold pb-10'/>
                    <div dangerouslySetInnerHTML={{__html: data?.topic}} className='text-white xl:text-md'/>
                </div>
                <div className='w-100 mid:1/4'>
                    <iframe width="560" height="315" src={`${data?.embed}&autoplay=1&loop=1&controls=1&rel=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

        </div>
    )
}

export default Chart