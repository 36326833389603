"use strict";

import { useState, useEffect } from "react";
import Banner from '../../assets/banner_showoff.png'
import QR from '../../assets/qrcode.png'
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'
import { session, clearSession, setSession } from '../../utils/AuthSession'
import SocialButton from "../../components/SocialButton";
import Dialog from '../../components/Dialog'

const Promo = () => {
    const [authSession, setAuthSession] = useState(session())
    const [data, setData] = useState({
        name: session()?.user?.firstname + ' '+ session()?.user?.lastname,
        phone: session()?.user?.phone,
        instagram: "",
        title: "",
        singer: "",
        songwriter: "",
        link: ""
    })
    const [reload,setReload] = useState(false)
    const [dialogTitle, setDialogTitle] = useState()
    const [dialogMessage, setDialogMessage] = useState()
    useEffect(() => {
        postIP();
    }, []);

    useEffect(() => {
    }, [data])
    const handleOnSuccess = () => {
        setAuthSession(session())
        
        window.location.reload(false);
    }
    console.log(session())
    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method: API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/showoff',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    const saveData = async (e) => {
        e.preventDefault()
        const reqBody = {
            name: data.name,
            phone: data.phone,
            instagram: data.instagram,
            title: data.title,
            singer: data.singer,
            songwriter: data.songwriter,
            link: data.link

        }

        const params = {
            access: 'auth',
            method: API.UPLOAD.type,
            url: API.UPLOAD.url,
            reqBody: reqBody
        }

        const response = await Api(params)
        console.log(response)
        if (response?.status) {
            setDialogTitle(response.message)
            setDialogMessage('Data sudah kami terima, kami akan segera memverifikasinya')
            setReload(true)
            document.getElementById('my_modal_1').showModal()
        } else {
            setDialogTitle('Error')
            setDialogMessage('Something wrong')
            setReload(false)
            document.getElementById('my_modal_1').showModal()
        }



    }
    const onChangeData = (e) => {

        switch (e.target.id) {
            case "name":
                setData({ ...data, name: e.target.value })
                break;
            case "phone":
                setData({ ...data, phone: e.target.value })
                break;
            case "instagram":
                setData({ ...data, instagram: e.target.value })
                break;
            case "singer":
                setData({ ...data, singer: e.target.value })
                break;
            case "title":
                setData({ ...data, title: e.target.value })
                break;
            case "songwriter":
                setData({ ...data, songwriter: e.target.value })
                break;
            case "link":
                setData({ ...data, link: e.target.value })
                break;
            default:
                break;
        }
    }

    return (
        <div className='p-5 text-white md:pl-20 md:pr-20 xl:pl-40 xl:pr-40 '>
            <div className=''><img src={Banner} className="w-
            
            " alt="" /></div>

            {authSession ? (
                <div className="flex bg-white mt-5 mb-5 rounded-2xl text-black justify-center">
                    <form className="m-5 w-full  2xl:w-[50%] xl:w-[75%] md:w-[80%]" onSubmit={saveData}>
                        <div className="mb-5">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Nama</label>
                            <input type="text" id="name" className="bg-gray-50 border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan nama anda" required onChange={onChangeData} value={data.name}/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 ">Telepon</label>
                            <input type="text" id="phone" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan telepon anda" required onChange={onChangeData} />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="instagram" className="block mb-2 text-sm font-medium text-gray-900 ">Akun Instagram</label>
                            <input type="text" id="instagram" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan akun instagram anda" required onChange={onChangeData} />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 ">Judul lagu</label>
                            <input type="text" id="title" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan judul lagu" required onChange={onChangeData} />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="singer" className="block mb-2 text-sm font-medium text-gray-900 ">Penyanyi</label>
                            <input type="text" id="singer" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan nama penyanyi" onChange={onChangeData} required />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="songwriter" className="block mb-2 text-sm font-medium text-gray-900 ">Pencipta</label>
                            <input type="text" id="songwriter" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan pencipta lagu" required onChange={onChangeData} />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 ">Link video</label>
                            <input type="text" id="link" className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:outline-none" placeholder="masukan link lagu anda" required onChange={onChangeData} />
                        </div>

                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input id="agreement" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label htmlFor="agreement" className="ms-2 text-sm font-medium text-gray-900"><a href="/privacy">Saya setuju dengan syarat dan ketentuan yang berlaku</a></label>
                        </div>
                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    </form>
                </div>
            ) : (
                <div className='flex pt-5 pb-5 gap-4 bg-white rounded-2xl mt-5 place-content-center justify-center items-center'>
                    <div>
                        <SocialButton onSuccess={handleOnSuccess} />
                    </div>
                    <div className="text-black text-xl"><b>Login untuk kirim Video Clip</b></div>
                </div>
            )}
            
            <dialog id="my_modal_1" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">{dialogTitle}</h3>
                    <p className="py-4">{dialogMessage}</p>
                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn" onClick={()=>{
                                if(reload){
                                    window.location.reload();
                                }
                            }}>Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>


    )
}

export default Promo