
import live_streaming from '../assets/live_streaming.png'
export const navs = [
    {
        id: 3,
        title: "MIC Chart",
        link: "/chart",
        type: "text"
    },
    {
        id: 2,
        title: live_streaming,
        link: "/live",
        type: 'image'
    },
    
    {
        id: 3,
        title: "Jadwal MIC",
        link: "/schedule",
        type: "text"
    },
    {
        id: 4,
        title: "Artikel",
        link: "/blogs",
        type: "text"
    },
    {
        id: 5,
        title: "Promosi",
        link: "/promo",
        type: "text"
    },
    {
        id: 6,
        title: "Berani Tampil",
        link: "/showoff",
        type: "text"
    },
]