import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import SocialButton from "../SocialButton";
import Logo from '../../assets/mic_logo.png'
import { navs } from '../../jsons/navs'
import { session, clearSession, setSession } from '../../utils/AuthSession'
import { gapi } from 'gapi-script';
import { GoogleLogin, GoogleLogout } from 'react-google-login'
// import Auth from '@/utils/auth'

const Navigation = (props) => {
    const [authSession, setAuthSession] = useState(session())


    const handleOnSuccess = () => {
        setAuthSession(session())
        window.location.reload(false);
    }

    const handleLogout = () =>{
        clearSession()
        setSession(session)
        window.location.reload(false);
    }

    return (

        <div className="navbar bg-white pl-5 pr-5 pt-0 pb-0" >
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost lg:hidden text-violet-950">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        {navs.map((nav, i) => (
                            <li key={i}>
                                <Link to={nav.link}>
                                    {nav.type === 'image' ? (
                                        <div className="w-12">
                                            <img src={nav.title}/>
                                        </div>
                                    ):(
                                        <div className="text-violet-800 font-semibold">{nav.title}</div>
                                    )}
                                </Link>
                            </li>


                        ))}

                    </ul>
                </div>
                <Link to='/'><img src={Logo} className="h-16" alt="logo" /></Link>
            </div>

            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1 focus:text-white active:text-white">
                    {navs.map((nav, i) => (
                        <li key={i}>
                            <Link to={nav.link} className="hover:text-pink-400 bg-transparent text-white active:text-blue-505">
                                {nav.type === 'image' ? (
                                    <div className="w-12">
                                        <img src={nav.title}/>
                                    </div>
                                ):(
                                    <div className="text-violet-800 font-semibold">{nav.title}</div>
                                )}
                            </Link>
                        </li>


                    ))}
                </ul>
            </div>
            <div className="navbar-end">
                {authSession ? (
                    <details className="dropdown">
                        <summary className="btn bg-transparent border-none text-white hover:bg-transparent">
                            <div className="flex gap-3 items-center" >
                                <div>{`Halow ${authSession.user.firstname}`}</div>
                                <div className="avatar">
                                    <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mr-5">

                                        <img src={authSession.user.image_profile} referrerPolicy="no-referrer"/>
                                    </div>
                                </div>

                            </div>
                        </summary>
                        <ul className="p-2 shadow menu dropdown-content z-[1]  w-52 bg-blue-950 text-white">
                            <li ><a className="hover:text-pink-400">Profile</a></li>
                            <li><a className="hover:text-pink-400" onClick={handleLogout}>Logout</a></li>
                        </ul>
                    </details>
                    // <div className="flex gap-3 items-center" >
                    //     <div>{`Halow ${authSession.user.firstname}`}</div>
                    //     <div className="avatar">
                    //         <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mr-5">

                    //             <img src={authSession.user.image_profile} />
                    //         </div>
                    //     </div>

                    // </div>
                ) : (
                    <div>
                        <SocialButton onSuccess={handleOnSuccess} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navigation
