'use client'

import React from 'react'
import MemberList from './MemberList'
import Chat from './Chat'



export default function ChatRoom() {
    return (
        <div className="container mx-auto">
            <div className="min-w-full  lg:grid lg:grid-cols-3">
                <Chat/>
            </div>
        </div>
    )
}

