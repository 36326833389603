import { useEffect, useState } from "react";
import Player from '../../components/Player'
import ChatRoom from '../../components/ChatRoom'
import PremiumBroadcast from '../../components/PremiumBroadcast'
import AdSense from 'react-adsense';
import AppDownload from '../../assets/app-download.png'
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'



const Live = () => {
    const [ip, setIP] = useState()
    const [sess,setSess] = useState('')
    let session
    // const history = useHistory();
    let body

    useEffect(() => {
        postIP('in');
        return () => {
            postIP(session);
        }
        
    }, []);

    const postIP = async (type) => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        if(ipaddress.data.ip !== '' && ipaddress.data.ip !== null){
            setIP(ipaddress.data.ip)
        }

        if(type === 'in'){
            body = {
                page: '/live',
                ip: ipaddress.data.ip,
                platform: "Web",
                

            }
        }else{
            body = {
                page: '/live',
                ip: ipaddress.data.ip,
                platform: "Web",
                session: type   
            }
        }
        const params = {
            url: API.IP.url,
            method: API.IP.type,
            access: 'no auth',
            reqBody: body
        }

        const response = await Api(params)
        if(response.status) {
            session = response.session
            setSess(response.session)
        }else{
            session = response.session
            setSess(response.session)
        }

    }

    // useBeforeunload(sess !== '' ? alertUser : null);

    return (
        <div className="p-4">
            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-2/3">
                    <Player />
                    {/* <PremiumBroadcast/> */}

                    <div className='pt-1 xl:hidden'>
                        <a href="https://play.google.com/store/apps/details?id=com.micstreaming">
                            <img src={AppDownload} alt="" />
                        </a>
                    </div>
                </div>

                <div className="min-h-fit md:w-1/3">
                    <div className='pb-1 hidden xl:block'>
                        <a href="https://play.google.com/store/apps/details?id=com.micstreaming">
                            <img src={AppDownload} alt="" />
                        </a>
                    </div>
                    <ChatRoom />
                    <AdSense.Google
                        client='ca-pub-2330402004842529'
                        slot='7806394673' />
                </div>
            </div>
            
        </div>
    )
}

export default Live