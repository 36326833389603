import React from 'react'

const Product = (props) => {
    return (
        <>
            <div className={props.className}>
                <div className='flex flex-col bg-blue-950 p-2 rounded-md'>
                    <div>
                        <img src={props.image} className='w-full' alt="product" />
                    </div>
                    
                    <div className='text-center font-medium mt-1 mb-1'>{props.name}</div>
                    <div>{props.feature}</div>
                    <div>Rp {parseFloat(props.price).toLocaleString('id-ID')}</div>
                </div>
            </div>

        </>

    )
}

export default Product