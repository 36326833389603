import React from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
function MainLayout() {
    return (
        <div className='flex flex-col h-screen'>
            <Navigation />
            <div>
                <Outlet/>
            </div>

            <Footer />
        </div>

    )
}

export default MainLayout