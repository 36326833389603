import React, { useRef } from 'react'
import ReactHlsPlayer from 'react-hls-player';

const Player = () => {
    const playerRef = React.useRef();
    return (
        <div className='rounded-xl p-1  bg-black'>
            <ReactHlsPlayer
                // src="https://mic.siar.us/mic/live/playlist.m3u8"
                src="https://v2.siar.us/mic/live/playlist.m3u8"
                hlsConfig={{
                    maxLoadingDelay: 4,
                    minAutoBitrate: 0,
                    lowLatencyMode: true,
                }}
                autoPlay={true}
                controls={true}
                width="100%"
                height="auto"
            />
        </div>
        
    )

}

export default Player