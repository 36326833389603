import { useEffect } from "react";
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'

const About = () => {
    useEffect(() => {
        postIP();
    }, []);

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method: API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/privacy',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }
    return (
        <div className='m-5 p-10 bg-white rounded-md text-slate-600'>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Kebijakan Privasi untuk Mic Streaming</div>
            <div className='pt-3 text-sm'>Mic Streaming, dapat diakses dari https://mic-indo.com/, salah satu prioritas utama kami adalah privasi pengunjung. Dokumen Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan dan dicatat oleh Mic Streaming dan cara kami menggunakannya.</div>
            <div className='pt-3 text-sm'>Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>File Log</div>
            <div className='pt-3 text-sm'>Mic Streaming mengikuti prosedur standar menggunakan file log. File-file ini mencatat pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting melakukan ini dan merupakan bagian dari analisis layanan hosting. Informasi yang dikumpulkan oleh file log termasuk alamat protokol internet (IP), jenis browser, Penyedia Layanan Internet (ISP), cap tanggal dan waktu, halaman rujukan/keluar, dan mungkin jumlah klik. Ini tidak terkait dengan informasi apa pun yang dapat diidentifikasi secara pribadi. Tujuan dari informasi tersebut adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis. Kebijakan Privasi kami dibuat dengan bantuan Generator Kebijakan Privasi.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Cookie dan Web Beacon</div>
            <div className='pt-3 text-sm'>Seperti situs web lainnya, Mic Streaming menggunakan "cookies". Cookies ini digunakan untuk menyimpan informasi termasuk preferensi pengunjung, dan halaman-halaman di situs web yang diakses atau dikunjungi pengunjung. Informasi tersebut digunakan untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web kami berdasarkan jenis browser pengunjung dan/atau informasi lainnya.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Google DoubleClick DART Cookie</div>
            <div className='pt-3 text-sm'>Google adalah salah satu vendor pihak ketiga di situs kami. Ia juga menggunakan cookie, yang dikenal sebagai cookie DART, untuk menayangkan iklan kepada pengunjung situs kami berdasarkan kunjungan mereka ke www.website.com dan situs lain di internet. Namun, pengunjung dapat memilih untuk menolak penggunaan cookie DART dengan mengunjungi kebijakan privasi jaringan iklan dan konten Google di URL berikut - https://policies.google.com/technologies/ads</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Kebijakan Privasi</div>
            <div className='pt-3 text-sm'>Anda dapat berkonsultasi dengan daftar ini untuk menemukan Kebijakan Privasi untuk masing-masing mitra periklanan Mic Streaming.</div>
            <div className='pt-3 text-sm'>Server iklan atau jaringan iklan pihak ketiga menggunakan teknologi seperti cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan masing-masing dan tautan yang muncul di Mic Streaming, yang dikirim langsung ke browser pengguna. Mereka secara otomatis menerima alamat IP Anda ketika ini terjadi. Teknologi ini digunakan untuk mengukur efektivitas kampanye iklan mereka dan/atau untuk mempersonalisasi konten iklan yang Anda lihat di situs web yang Anda kunjungi.</div>
            <div className='pt-3 text-sm'>Perhatikan bahwa Mic Streaming tidak memiliki akses atau kontrol terhadap cookie ini yang digunakan oleh pengiklan pihak ketiga.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Kebijakan Privasi Pihak Ketiga</div>
            <div className='pt-3 text-sm'>Kebijakan Privasi Mic Streaming tidak berlaku untuk pengiklan atau situs web lain. Oleh karena itu, kami menyarankan Anda untuk berkonsultasi dengan Kebijakan Privasi masing-masing server iklan pihak ketiga ini untuk informasi lebih rinci. Ini mungkin mencakup praktik dan instruksi mereka tentang cara memilih untuk tidak ikut serta dalam opsi tertentu.</div>
            <div className='pt-3 text-sm'>Anda dapat memilih untuk menonaktifkan cookie melalui opsi browser individual Anda. Untuk mengetahui informasi lebih detail mengenai pengelolaan cookie dengan browser web tertentu, dapat ditemukan di situs web browser masing-masing. Apa itu Cookies?</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Informasi Anak</div>
            <div className='pt-3 text-sm'>Bagian lain dari prioritas kami adalah menambahkan perlindungan bagi anak-anak saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengamati, berpartisipasi, dan/atau memantau dan membimbing aktivitas online mereka.</div>
            <div className='pt-3 text-sm'>Mic Streaming tidak dengan sengaja mengumpulkan Informasi Identifikasi Pribadi apa pun dari anak-anak di bawah usia 13 tahun. Jika menurut Anda anak Anda memberikan informasi semacam ini di situs web kami, kami sangat menganjurkan Anda untuk segera menghubungi kami dan kami akan melakukan upaya terbaik kami untuk segera menghapus informasi tersebut dari catatan kami.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Hanya Kebijakan Privasi Online</div>
            <div className='pt-3 text-sm'>Kebijakan Privasi ini hanya berlaku untuk aktivitas online kami dan berlaku bagi pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan/atau kumpulkan di Mic Streaming. Kebijakan ini tidak berlaku untuk informasi apa pun yang dikumpulkan secara offline atau melalui saluran selain situs web ini.</div>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Ketentuan Upload Video Clip di MIC TV</div>
            <ul className="list-disc ml-5">
                <li className='pt-3 text-sm'>Lagu/Video Clip yang di upload merupakan <b>karya orisinil</b>, bukan dalam bentuk mengcover dari lagu karya orang lain.</li>
                <li className='pt-3 text-sm'>Pengirim kontent menyerahkan secara penuh kepada MIC TV untuk menyebarkan konten Video Clipnya untuk disebar di platform yang dimiliki MIC TV.</li>
                <li className='pt-3 text-sm'>Konten Video Clip yang cocok dengan visi misi dari mIC TV, akan dibantu di Promosian melalui platform yang dimiliki MIC TV</li>
                <li className='pt-3 text-sm'>Jika dikemudian hari pihak MIC TV mendapatkan tuntutan atas isi dari Video Clip yang dikirimkan pada MIC TV. Maka diluar tanggung jawab MIC TV.</li>
            </ul>
            <div className='flex border-blue-900 border-b font-semibold pt-4'>Izin</div>
            <div className='pt-3 text-sm'>Dengan menggunakan situs web kami, Anda dengan ini menyetujui Kebijakan Privasi kami dan menyetujui Syarat dan Ketentuannya.</div>
        </div>
    )
}

export default About