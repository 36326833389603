import React from 'react';
import { products } from '../../jsons/products';
import Product from '../../components/Product';

const Store = () => {
    return (
        <>
            <div className='flex gap-2 flex-wrap justify-center mt-10'>
                {products.map((product,i)=>(
                    <Product 
                        key={i} 
                        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4"
                        name={product.name} 
                        feature={product.feature} 
                        image={product.image} 
                        price={product.price}/>
                ))}
            </div>
            
        </>
    )
}

export default Store