"use strict";

import { useEffect } from "react";
import Banner from '../../assets/banner_iklan_mic.png'
import QR from '../../assets/qrcode.png'
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'

const Promo = () => {

    useEffect(() => {
        postIP();
    }, []);

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method: API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/promo',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    return (
        <div className='p-5 text-white md:pl-20 md:pr-20 xl:pl-40 xl:pr-40 '>
            <div className=''><img src={Banner} className="w-
            
            " alt="" /></div>
            <div className='flex pt-5 gap-4'>
                <div className='w-1/3'>
                    <img src={QR} alt="" />
                </div>
                <div className='w-2/3 flex flex-col justify-center items-center'>
                    <div className='text-2xl font-semibold'>Pasang Iklan SEKARANG !</div>
                    <div className='text-3xl font-semibold'>WA : 0819-1387-0479</div>
                </div>
            </div>
        </div>
    )
}

export default Promo