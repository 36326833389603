"use client";
"use strict";
import React, { useState, useEffect, useRef } from 'react'
import { chatMessages } from '../../jsons/chatMessages'
import { session } from '../../utils/AuthSession';
import { useForm } from "react-hook-form";
import Ws from "@lukinco/adonis-websocket-client";
import bkgr from '../../assets/background-tile.png'
import saveToLocalStorage from '../../utils/saveToLocalStorage';
import { getMessagesFromLocalStorage } from '../../utils/getMessagesFromLocalStorage';
import { Api } from '../../utils/apiServer'
import { API } from '../../utils/api'

import Picker from 'emoji-picker-react';
// import 'emoji-picker-react/dist/emoji-picker-react.cjs.development.css';

const socket = Ws(process.env.REACT_APP_WEB_SOCKET_URL).connect()
const channel = socket.subscribe('livechat');

const EmojiData = ({ chosenEmoji }) => {

}

export default function Chat() {
    const [wsConnect, setWsConnect] = useState(false)
    const [authSession, setAuthSession] = useState(session())
    const [messages, setMessages] = useState([])
    const responseBody = { firstName: "", lastName: "", age: "0" }
    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm();
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const scrollRef = useRef()

    useEffect(() => {
        init()
        return () => {
            // channel.close()
        }
    }, [])

    channel.on('message', async (m) => {
        let me = [...messages, {
            user_id: m.user_id,
            firstname: m.firstname,
            image_profile: m.image_profile,
            message: m.message
        }]
        setMessages(me)

    })

    useEffect(() => {
    }, [wsConnect])

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const init = async () => {
        const params = {
            access: 'none',
            method: API.GET_LIVECHATS.type,
            url: API.GET_LIVECHATS.url,
            reqBody: {}

        }
        const response = await Api(params);
        if (response.status) {
            setMessages(response.data)
        }
    }


    const onSubmit = async (data) => {
        const reqBody = {
            message: data.message
        }

        const params = {
            access: 'auth',
            method: API.LIVECHAT.type,
            url: API.LIVECHAT.url,
            reqBody: reqBody
        }

        const response = await Api(params)
        
        setValue('message', '')

    }

    const handleEmojiClick = (emojiObject, event) => {
        setChosenEmoji(emojiObject);
        setValue('message', getValues('message') + emojiObject.emoji)
    };








    const scrollToBottom = () => {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleDeleteMessages = () => {
        localStorage.removeItem('messages');
        setMessages(getMessagesFromLocalStorage())
    }

    return (
        <div className="lg:col-span-3 lg:block rounded-xl">
            <div className="w-full">

                {authSession ? (
                    <div className="relative flex justify-between items-center p-3  bg-black rounded-t-xl">
                        <div className='flex items-center'>
                            <img className="object-cover w-10 h-10 rounded-full"
                                src={authSession.user.image_profile} alt="username" />
                            <span className="block ml-2 font-bold text-gray-600">{authSession.user.firstname}</span>
                            <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3">
                            </span>
                        </div>
                    </div>
                ) : (
                    ''
                )}


                <div className="relative w-full p-6 overflow-y-auto h-[40rem] bg-repeat " style={{ backgroundImage: `url(${bkgr})` }}>
                    <ul className="space-y-2">
                        {messages.map((msg, i) => (
                            msg?.user_id != authSession?.user?.user_id ? (


                                <div key={i} className="chat chat-start">
                                    <div className="chat-image avatar">
                                        <div className="w-10 rounded-full">
                                            <img src={msg.image_profile} referrerPolicy="no-referrer" />
                                        </div>
                                    </div>
                                    <div className="chat-bubble text-sm">{`${msg.firstname}: ${msg.message}`}</div>
                                </div>


                            ) : (


                                <div key={i} className="chat chat-end">
                                    <div className="chat-image avatar">
                                        <div className="w-10 rounded-full">
                                            <img src={msg.image_profile} referrerPolicy="no-referrer" />
                                        </div>
                                    </div>
                                    <div className="chat-bubble text-sm">{`${msg.message}`}</div>
                                </div>


                            )
                        ))}
                        <div ref={scrollRef}></div>

                    </ul>

                </div>

                {authSession ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex items-center justify-between w-full p-3 bg-black">

                            <div className="dropdown dropdown-top dropdown-start">
                                <label tabIndex={0} className="btn m-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </label>
                                <div tabIndex={0} className="dropdown-content z-[1] w-64 ">

                                    <Picker onEmojiClick={handleEmojiClick} emojiStyle="google" width="100" searchDisabled>
                                        {chosenEmoji && <EmojiData chosenEmoji={chosenEmoji} />}
                                    </Picker>

                                </div>
                            </div>
                            < input type="text" placeholder="Message"
                                className="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-full outline-none focus:text-gray-700 text-black"
                                {...register("message", { required: true })} />

                            <button type="submit">
                                <svg className="w-5 h-5 text-gray-500 origin-center transform rotate-90" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                </svg>
                            </button>
                        </div>
                    </form>

                ) : (``)
                }


            </div >
        </div >
    )
}
