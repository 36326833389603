import React from 'react'
import MicLogo from '../../assets/mic-white-big.png'
import PlayStore from '../../assets/playstore.png'
function MicSection() {
    return (
        <div>
            <div className='flex justify-center'>
                <a href="https://play.google.com/store/apps/details?id=com.micstreaming">
                    <img src={PlayStore} alt="" />
                </a>
                
            </div>
            <div className='pt-20 pb-20 md:flex justify-center items-center gap-10 md:pl-10 md:pr-10'>
                <div className='md:w-1/3'>
                    <img src={MicLogo} alt="" />
                </div>
                <div className='text-white xl:text-4xl md:text-2xl sm:text-xl xl:pt-0 md:pt-0 sm:pt-10 md:w-2/3'>
                    MIC TV, Channel yang menayangkan lagu-lagu ternama 100% Indonesia, baik itu lagu Pop, dangdut, jawa, video lirik maupun lagu melayu.
                </div>

            </div>
        </div>
    )
}

export default MicSection