import React, { useState, useEffect, useRef } from 'react'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import { Link } from 'react-router-dom'
import axios from 'axios'

const BlogPage = () => {

    const [blogs, setBlogs] = useState([])
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(10)
    const [lastPage, setLastPage] = useState()
    const [total, setTotal] = useState()

    useEffect(() => {
        init()
        postIP()
    }, [])

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method : API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/blogs',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    const init = async () => {
        const params = {
            url: API.BLOGS.url,
            access: 'no auth',
            method: 'get',
            reqBody: {
                page: page,
                rows: rows
            }
        }

        const response = await Api(params)
        if (response.status) {
            setLastPage(response.data.lastPage)
            setTotal(response.data.total)
            setBlogs(response.data.data)
        }

    }

    return (
        <>
            <div className='p-10 text-white'>
                <div className='font-bold text-2xl pb-5'>Artikel Terkini</div>
                {blogs.map((blog, i) => (
                    <div key={i} className='flex flex-row gap-5 pb-5 border-b-white border-b'>
                        <div className='w-1/3 md:w-1/6'><img src={blog.image} className='rounded-xl' alt="" /></div>
                        <div className='w-2/3 md:w-5/6'>
                            <Link to={`/blog/${blog.slug}`} className='font-semibold text-xl hover:text-sky-400'>{blog.title}</Link>
                            
                            <div className='pt-3'>{blog.short_content}</div>
                        </div>

                    </div>
                ))}
                <div className="join">
                    <button className="join-item btn">«</button>
                    <button className="join-item btn">{page}</button>
                    <button className="join-item btn">»</button>
                </div>
            </div>

        </>


    )
}

export default BlogPage