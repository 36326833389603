import React from 'react'
import Logo from '../../assets/mic-white-small.png'
import Instagram from '../../assets/icon_Instagram.png'
import Mediacartz from '../../assets/Powered2.png'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='flex justify-between items-center p-5' style={{backgroundColor: "#372B69"}}>
            <div className='w-24'>
                <img className="w-full" src={Mediacartz} alt="Logo" />
            </div>
            <div className='flex gap-3 justify-center items-center'>
                <Link to="/privacy" className='hover:text-sky-500 text-sm text-slate-300'>Syarat dan Ketentuan</Link>
                <div className='w-24'>
                    <img src={Logo} alt="Logo" />
                </div>
                
            </div>

        </div>
    )
}

export default Footer