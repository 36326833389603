export const API = {
    LIVECHAT: {
		url : 'livechat',
		type: 'post',
	},
	GET_LIVECHATS: {
		url: 'livechat',
		type: 'get'
	},
	GET_BANNERS: {
		url: 'public/banners',
		type: 'get'
	},
	MICCHART: {
		url: 'public/micchart',
		type: 'get'
	},
	BLOGS: {
		url: 'public/blogs',
		type: 'get'
	},
	BLOG: {
		url: 'public/blog',
		type: 'get'
	},
	IP: {
		url: 'public/ip',
		type: 'post'
	},
	UPLOAD: {
		url: 'upload',
		type: 'post'
	}

}