export const products = [
    {
        product_id:1,
        name: 'Walnut Raisin Premium Pack',
        image: 'https://cdn.mediacartz.com/files/origin/product_walnut_raisin.jpg',
        feature: 'Freature',
        price: 50000
    },
    {
        product_id:2,
        name: 'Sourdough Country Loaf',
        image: 'https://cdn.mediacartz.com/files/origin/product_sourdough_country_loaf.jpg',
        feature: 'Freature',
        price: 50000
    },
    {
        product_id:3,
        name: 'Baguette',
        image: 'https://cdn.mediacartz.com/files/origin/product_baguette.jpg',
        feature: 'Freature',
        price: 50000
    },
    {
        product_id:4,
        name: 'Walnut Chocochip',
        image: 'https://cdn.mediacartz.com/files/origin/product_walnut_chocochip.jpg',
        feature: 'Freature',
        price: 50000
    },
    {
        product_id:5,
        name: 'Double Choco',
        image: 'https://cdn.mediacartz.com/files/origin/product_double_choco.jpg',
        feature: 'Freature',
        price: 50000
    },
    {
        product_id:6,
        name: 'Banana Cake',
        image: 'https://cdn.mediacartz.com/files/origin/product_banana_cake.jpg',
        feature: 'Freature',
        price: 50000
    }
]