"use strict";
import { useEffect } from "react";
import Carousel from '../../components/Carousel'
import MicSection from '../../components/Section/MicSection'
import HitSection from '../../components/Section/HitSection'
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'

const Home = () => {

    useEffect(() => {
            postIP();
    }, []);

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method : API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    return (
        <div>
            <Carousel/>
            <MicSection/>
            <HitSection/>
        </div>
    )
}

export default Home