
import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"
import moment from "moment"
import Banner from "../../assets/banner-mic-chart.png"
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";

const Schedule = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        Init();
        postIP();
    }, [])

    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method : API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/schedule',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }

        const response = await Api(params)
    }

    const Init = async () => {
        const response = await axios.get(`${process.env.REACT_APP_CORE_MIC_SCHEDULES}schedule`);
        if (response?.data?.status) {
            setData(response.data.data)
        }
    }
    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='m-2 xl:w-1/2 md:w-1/2'>
                <img src={Banner} alt="" />
            </div>
            <div className='pt-5 pb-2 text-center text-2xl font-semibold text-white'>Jadwal MIC Hari Ini</div>
            <div className='flex flex-col justify-center items-center pb-10'>
                <div>
                    {data.map((d, i) => (
                        <div key={i} className='flex gap-4'>
                            <div className='text-sm text-white'>{moment(`${d.date} ${d.start_time}`).format("HH:mm")}</div>
                            <div>
                                <div className='text-sm text-amber-200 font-semibold'>{d.program.program}</div>
                                <div className='text-xs text-white'>{d.program.program_desc}</div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default Schedule