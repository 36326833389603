import { useState, useEffect } from 'react'
import { hit_sections } from '../../jsons/hit_section'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'


function HitSection() {
    const [hit, setHit] = useState()


    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const params = {
            url: API.MICCHART.url,
            method: API.MICCHART.type,
            access: 'no auth',
            reqBody: {}
        }

        const response = await Api(params)
        if (response.status) {
            setHit(response.data)
        }
    }

    return (
        <div>

            <div className={`pt-20 pb-20 md:flex gap-10 `}>
                <div className='xl:p-10 xl:pl-20 sm:p-5 bg-white md:rounded-r-3xl sm:rounded-none'>
                    <img src={hit?.hit_image} className="sm:w-full" alt="" />
                </div>
                <div className='xl:w-2/3 md:w-2/3'>
                    <div className='font-bold antialiased mb-5 xl:text-5xl md:text-3xl sm:pt-5 sm:text-3xl sm:pl-5 md:p-0 xl:p-0 text-white'>Lagu Hits Minggu ini!</div>
                    <div dangerouslySetInnerHTML={{ __html: hit?.hit_title }} className='custom font-semibold xl:text-3xl md:text-2xl sm:pl-5 sm:text-2xl sm:mb-5 xl:pl-0 md:pl-0 text-white' />
                    <div dangerouslySetInnerHTML={{ __html: hit?.hit_content }} className='text-white xl:text-xl' />

                </div>
            </div>
        </div>

    )
}

export default HitSection