import React, {useState, useEffect, useRef} from 'react'
// import { banners as banner } from '../../jsons/banners'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'


let count = 0
function Carousel() {
    const [currentIndex, setCurrentIndex] = useState(0)
    const sliderRef = useRef()
    const [banners,setBanners] = useState([])
    let interval


    useEffect(()=>{
        // startSlider();
        init();
        // startSlider()
        
    },[])
    
    useEffect(()=> {
        if(banners.length > 0) {
            interval = setInterval(()=>{
                handleNextButton()
            },3000)
    
            sliderRef.current.addEventListener('animationend',removeAnimation)
            return () => clearInterval(interval)
        }
        
    },[banners])

    const init = async () => {
        const params = {
            url: API.GET_BANNERS.url,
            method:  API.GET_BANNERS.type,
            access: 'no auth',
            reqBody: {}
        }
        const response =  await Api(params)
        if(response.status){
            setBanners(response.data)
        }

    }
    
    const removeAnimation = () => {
        sliderRef.current.classList.remove('fade-anim')
    }

    const startSlider = () => {
        let interval = setInterval(()=>{
            handleNextButton()
        },3000)

        sliderRef.current.addEventListener('animationend',removeAnimation)
        return () => clearInterval(interval)
    }

    const handlePrevButton = () => {
        
        count = (currentIndex + banners.length - 1) % banners.length
        setCurrentIndex(count)
        sliderRef.current.classList.add('fade-anim')
    }

    const handleNextButton = () => {
        count = (count + 1) % banners.length
        setCurrentIndex(count) 
        sliderRef.current.classList.add('fade-anim')
    }

    return (
        <div className='p-5'>
            <div ref={sliderRef} className="w-full select-none relative">
                <div className="aspect-w-16 aspect-h-9">
                    <a href={banners[currentIndex]?.link}>
                    <img src={banners[currentIndex]?.url} className="w-full" />    
                    </a>
                    
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <button className="btn btn-circle" onClick={handlePrevButton}>❮</button>
                        <button className="btn btn-circle" onClick={handleNextButton}>❯</button>
                    </div>
                </div>

            </div>
            
        </div>
    )
}

export default Carousel