import React, { useState, useEffect, useRef } from 'react'
import MicLogo from '../../assets/mic_logo.png'
import MediaCartzLogo from '../../assets/Powered2.png'
import Ri78 from '../../assets/hutri78.png'
import Ws from "@lukinco/adonis-websocket-client";


function RunningText() {
    const [msg, setMsg] = useState('')
    const [isOut, setIsOut] = useState(true)
    const textRef = useRef()

    function Socket(ch) {
        const socket = Ws(process.env.REACT_APP_WEB_SOCKET_URL).connect();
        socket.on('open', () => {
            subscribeToChannel(socket,ch)
        })
    
        socket.on('error', () => {
    
        })
    }


    function subscribeToChannel(socket,ch) {
        const channel = socket.subscribe(ch)
    
        channel.on('error', () => {
            
        })
    
        channel.on('message', (message) => {
            setMsg(message)
        })
    }

    useEffect(() => {

        Socket('runningtext');
        if (msg.status) {
            setIsOut(false)
            setTimeout(() => {
                setIsOut(true)

            }, 12000)
        }

    }, [msg])

    const addAnimation = () => {
        textRef.current.classList.add('fade-anim')
    }




    return (
        <div className='h-screen  bg-black'>
            <div className=' flex justify-between '>
                <div className='w-1/3 pl-16 pr-16 pt-2'>
                    <img src={Ri78} alt="" />
                </div>
                <div className='w-1/3 pl-16 pr-16 pt-2'>
                    <img src={MicLogo} alt="" />
                </div>

            </div>

            <div className={`text-white text-2xl overflow-hidden  ${!isOut ? 'premium-fade-in' : 'premium-fade-out'}`} ref={textRef}>
                <div className='flex justify-center items-center pl-1 pr-2' style={{ marginTop: "610px" }}>

                    <img className="rounded-full" src={msg?.data?.user?.image_profile} referrerPolicy="no-referrer" alt="profile" />
                    <div className='text-yellow-400 pl-5'>"{msg?.data?.user?.firstname}" </div>
                    <div className='text-white pl-5'>{msg?.data?.message}</div>
                </div>
            </div>

        </div>


    )
}

export default RunningText