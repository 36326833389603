import React from 'react'

function Profile() {
    return (
        <div className='flex h-full '>
            <div>
                photo profile
            </div>
            <div>
                <div>
                    Nama
                </div>
                <div>
                    email
                </div>
                <div>
                    <button>Edit</button>
                </div>
            </div>
        </div>
    )
}

export default Profile